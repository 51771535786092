interface IPictureSources {
  webp?: string;
  jpg: string;
  className?: string;
  width?: number;
  height?: number;
  alt?: string;
}

const PictureElement = ({
  webp,
  jpg,
  className,
  width,
  height,
  alt,
}: IPictureSources) => {
  return (
    <picture className={className ? `${className}-element` : ""}>
      {webp ? <source srcSet={webp} type="image/webp"></source> : ""}
      <source srcSet={jpg} type="image/jpeg"></source>
      <img src={jpg} className={className} alt="jpg" />
    </picture>
  );
};

export default PictureElement;
