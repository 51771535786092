import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import slider from "../../assets/carousel-cards/slider.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselCard, { IBusinessProps } from "../../components/CarouselCard";
import { MobileViewContext } from "../../mobileView/mobileViewProvider";
import PictureElement from "../../components/PictureElement";
import "./index.css";

const VideosCarousel = () => {
  const { isMobileView } = useContext(MobileViewContext);
  const language = window.carouselLang === "ro" ? "ro" : "en";

  const foldRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const hoverIntervalRef = useRef<NodeJS.Timer>();

  const [sliderData, setSliderData] = useState(slider[language]);
  useEffect(() => {
    setSliderData(slider[language]);
  }, [language]);

  const preselectedCard: IBusinessProps =
    Object.values(sliderData).find((el: IBusinessProps) => el.preselected) ||
    Object.values(sliderData)[0];
  const previewURL = preselectedCard.previewURL;

  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [closed, setClosed] = useState(false);

  const [name, setName] = useState(preselectedCard.name);
  const [videoURL, setVideoUrl] = useState(preselectedCard.videoURL);
  const [imageURL, setImageURL] = useState(preselectedCard.imageURL);

  const [mouseEnterTimestamp, setMouseEnterTimestamp] = useState<number>(0);
  const [hoveredCard, setHoveredCard] = useState<IBusinessProps>();
  const [clickedCard, setClickedCard] = useState<IBusinessProps>();
  const [activeCard, setActiveCard] = useState<IBusinessProps>(preselectedCard);

  const restartPlayer = () => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(0, "seconds");
    }
  };

  const handleOnClick = useCallback((cardData: IBusinessProps) => {
    setClickedCard(cardData);
    setVideoUrl(cardData.videoURL);
    setPlaying(true);
    setMuted(false);
    setClosed(false);
  }, []);

  const handleOnHoverEnter = useCallback((cardData: IBusinessProps) => {
    setMouseEnterTimestamp(Date.now());
    setHoveredCard(cardData);
  }, []);

  const handleOnHoverLeave = useCallback(() => {
    clearInterval(hoverIntervalRef.current);
    setHoveredCard(undefined);
  }, []);

  const handleClose = useCallback(() => {
    setClosed(true);
  }, []);

  const handleActive = useCallback(
    (card: IBusinessProps) => {
      return card === activeCard;
    },
    [activeCard]
  );

  useEffect(() => {
    if (clickedCard) {
      setActiveCard(clickedCard);
    } else if (hoveredCard) {
      setActiveCard(hoveredCard);
      hoverIntervalRef.current = setInterval(() => {
        if (Date.now() - mouseEnterTimestamp > 280) {
          clearInterval(hoverIntervalRef.current);
          setVideoUrl(hoveredCard.videoURL);
          setMuted(true);
          setTimeout(() => {
            setPlaying(true);
          }, 100);
        }
      }, 100);
    }
    setImageURL(activeCard.imageURL);
    setName(activeCard.name);

    const card = document
      .getElementById(preselectedCard.name)
      ?.getElementsByClassName("card card-carousel");

    return () => clearInterval(hoverIntervalRef.current);
  }, [
    clickedCard,
    hoveredCard,
    mouseEnterTimestamp,
    activeCard.imageURL,
    activeCard.name,
  ]);

  useEffect(() => {
    if (closed) {
      setClickedCard(undefined);
      setHoveredCard(undefined);
      setPlaying(false);
      restartPlayer();
    }
  }, [closed]);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        if (clickedCard) {
          setClosed(true);
        }
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [clickedCard]);

  useEffect(() => {
    const businessContainerObserver = new IntersectionObserver(
      (entries) => {
        // if player is no longer visible in the screen
        if (!entries[0].isIntersecting) {
          // if video was started
          if (hoveredCard || clickedCard) {
            // reset active card
            const card = document
              .getElementById(activeCard.name)
              ?.getElementsByClassName("card card-carousel");
            setActiveCard(preselectedCard);

            // stop video and reset it
            setClickedCard(undefined);
            setHoveredCard(undefined);
            setPlaying(false);
            restartPlayer();
          }
        }
      },
      { threshold: [0] }
    );

    const businessContainer = document.getElementById("businessContainerId");
    if (businessContainer) {
      businessContainerObserver.observe(businessContainer);
    }

    return () => {
      if (businessContainer)
        businessContainerObserver.unobserve(businessContainer);
    };
  }, [hoveredCard, clickedCard, preselectedCard]);

  const owlCarouselMemo = useMemo(() => {
    const owlResponsive = {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    };

    return (
      <OwlCarousel
        className="owl-theme business-slider"
        margin={10}
        dots
        responsive={owlResponsive}
      >
        {Object.keys(sliderData).map((key) => {
          let cardData = sliderData[
            key as keyof typeof sliderData
          ] as IBusinessProps;
          return (
            <div
              id={cardData.name}
              className="item"
              key={key}
              onClick={() => {
                handleOnClick(cardData);

                if (foldRef && foldRef.current) {
                  let wWidth = window.innerWidth;
                  // For "The Coop Network Business" title
                  // let offset =
                  //   wWidth > 1500 ? (wWidth * 8.7) / 100 : (wWidth * 8.4) / 100;
                  let offset =
                    wWidth > 1500
                      ? 0 - (wWidth * 2.1) / 100
                      : (wWidth * 3.5) / 100;
                  let elementPosition =
                    foldRef.current.getBoundingClientRect().top;
                  let offsetPosition =
                    elementPosition + window.scrollY + offset;

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  });
                  // foldRef.current.scrollIntoView({ behavior: "smooth" });
                }
              }}
              onMouseEnter={() => {
                handleOnHoverEnter(cardData);
              }}
              onMouseLeave={() => {
                handleOnHoverLeave();
              }}
            >
              <CarouselCard data={cardData} active={handleActive(cardData)} />
            </div>
          );
        })}
      </OwlCarousel>
    );
  }, [handleOnClick, handleOnHoverEnter, handleOnHoverLeave]);

  const desktopView = (
    <div className="carousel-wrapper">
      <div
        id="businessContainerId"
        className={`business-preview business-container ${
          playing
            ? clickedCard
              ? "business-playing-loud"
              : "business-playing"
            : closed
            ? "video-closed"
            : // ? activeCard !== preselectedCard
              //   ? "business-preview"
              //   : ""
              ""
        } ${`video-` + name.split(" ").join("-")}
          `}
        onClick={() => setPlaying(true)}
      >
        <span className="business-name">{name}</span>
        <div className="business-image">
          <PictureElement
            webp={process.env.REACT_APP_PUBLIC_URL + imageURL}
            jpg={process.env.REACT_APP_PUBLIC_URL + imageURL.replace(".webp", ".jpg")}
          />
        </div>
        <video
          className="business-preview-video"
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
        >
          <source
            data-src={process.env.REACT_APP_PUBLIC_URL + previewURL}
            type="video/mp4"
            src={process.env.REACT_APP_PUBLIC_URL + previewURL}
          ></source>
        </video>
        <img
          className="business-video-close"
          src={process.env.REACT_APP_PUBLIC_URL + '/icons/close-light.svg'}
          onClick={() => {
            handleClose();
          }}
        />
        <ReactPlayer
          ref={playerRef}
          className="business-video fade-in"
          url={videoURL}
          playing={playing}
          muted={muted}
          volume={0.7}
          onStart={() => {
            if (!hoveredCard && !clickedCard) {
              setPlaying(false);
            }
          }}
          onEnded={() => {
            setPlaying(false);
            setImageURL(activeCard.imageURL);
            setName(activeCard.name);
          }}
          style={{ display: playing ? "block" : "none" }}
        />
      </div>

      <div className="carousel-container">{owlCarouselMemo}</div>
    </div>
  );

  const mobileView = (
    <OwlCarousel
      className="owl-theme business-slider"
      margin={10}
      dots
      items={1}
    >
      {Object.keys(sliderData).map((key, idx) => {
        let data = sliderData[key as keyof typeof sliderData];
        return (
          <div className="item" key={key}>
            <CarouselCard data={data} idx={idx} active={true} />
          </div>
        );
      })}
    </OwlCarousel>
  );

  if (isMobileView) {
    return mobileView;
  }

  return desktopView;
};

export default VideosCarousel;
