import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

declare global {
  interface Window {
    carouselLang: string;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("pluginVideoSl") as HTMLElement
);
root.render(<App />);
