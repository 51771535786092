import VideosCarousel from "./components/VideosCarousel";
import { MobileViewProvider } from "./mobileView/mobileViewProvider";

function App() {
  return (
    <MobileViewProvider>
      <div className="App">
        <div className="videos-carousel-container">
          <VideosCarousel />
        </div>
      </div>
    </MobileViewProvider>
  );
}

export default App;
