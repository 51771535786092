import { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./index.css";
import { MobileViewContext } from "../../mobileView/mobileViewProvider";
import PictureElement from "../PictureElement";

export interface IBusinessProps {
  name: string;
  title: string;
  text: string;
  previewURL: string;
  imageURL: string;
  videoURL: string;
  preselected: boolean;
}

export interface ICarouselCardProps {
  data: IBusinessProps;
  active: boolean;
  idx?: number;
}

const CarouselCard = ({ data, idx, active }: ICarouselCardProps) => {
  const { isMobileView } = useContext(MobileViewContext);

  const cardRef = useRef<any>(null);
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!cardRef.current?.contains(event.target)) {
        setClicked(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [cardRef]);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setClicked(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [clicked]);

  const desktopView = (
    <div
      ref={cardRef}
      className={
        "card card-carousel card-" + "active"
        // (hovered || clicked || data.preselected ? "active" : "inactive")
      }
      onClick={() => {
        setClicked(true);
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className="card-name">
        {data.name}
        <img className="card-play-icon" alt="card-play" src={process.env.REACT_APP_PUBLIC_URL + '/icons/coop-video-play.svg'} />
      </div>
      <div className="card-content">
        <h3 className="card-title">{data.title}</h3>
        <p className="card-text">{data.text}</p>
      </div>
    </div>
  );

  const mobilePlayerRef = useRef<ReactPlayer>(null);
  const [mobilePlaying, setMobilePlaying] = useState(true);
  const [mobileClicked, setMobileClicked] = useState(false);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(0);
  const [mutedMobile, setMutedMobile] = useState(true);
  const [closed, setClosed] = useState(false);

  const restartPlayer = () => {
    if (mobilePlayerRef && mobilePlayerRef.current) {
      mobilePlayerRef.current.seekTo(0, "seconds");
    }
  };

  useEffect(() => {
    if (mobileClicked) {
      setMobilePlaying(true);
      setMuted(false);
      setClosed(false);
    }
  }, [mobileClicked]);

  useEffect(() => {
    if (closed) {
      setMobileClicked(false);
      setMobilePlaying(false);
      restartPlayer();
    }
  }, [closed]);

  useEffect(() => {
    const videoObserver = new IntersectionObserver(
      (entries) => {
        // if player is no longer visible in the screen
        if (!entries[0].isIntersecting) {
          // if video was started
          if (mobileClicked) {
            // stop and restart it
            setMobileClicked(false);
            setMobilePlaying(false);
            restartPlayer();
          }
        }
      },
      { threshold: [0] }
    );

    const video = document.getElementById(`card-video-${data.name}`);
    if (video) {
      videoObserver.observe(video);
    }

    return () => {
      if (video) videoObserver.unobserve(video);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileClicked]);

  const handleVolume = useCallback(() => {
    setVolume(0.7);
    setMutedMobile(false);
  }, []);

  const mobileView = (
    <div
      className={
        "card card-carousel card-active " +
        (mobileClicked ? "card-playing" : "")
      }
      onClick={() => {
        setMobileClicked(true);
      }}
    >
      <div className="card-video-player-mobile-wrapper">
        <img
          alt="close"
          className="card-video-close"
          src={process.env.REACT_APP_PUBLIC_URL + '/icons/close-light.svg'}
          onClick={() => {
            setClosed(true);
          }}
        />

        {mobileClicked && (
          <ReactPlayer
            ref={mobilePlayerRef}
            id={`card-video-${data.name}`}
            className="card-video-player-mobile"
            url={data.videoURL}
            playing={mobilePlaying}
            volume={volume}
            muted={mutedMobile}
            onPause={handleVolume}
            onEnded={() => {
              setClosed(true);
              setMobilePlaying(false);
            }}
          />
        )}
      </div>
      <div className="business-image">
        <PictureElement
          webp={process.env.REACT_APP_PUBLIC_URL + data.imageURL}
          jpg={process.env.REACT_APP_PUBLIC_URL + data.imageURL.replace(".webp", ".jpg")}
        />
        <img alt="card-play-icon" className="card-play-icon" src={process.env.REACT_APP_PUBLIC_URL + '/icons/coop-video-play.svg'} />
      </div>
      <div className="card-name">{data.name}</div>
      <div className="card-content">
        <h3 className="card-title">{data.title}</h3>
        <p className="card-text">{data.text}</p>
      </div>
    </div>
  );

  if (isMobileView) {
    return mobileView;
  }

  return desktopView;
};

export default CarouselCard;
