import { createContext } from "react";
import useIsMobile from "../hooks/useIsMobile";

export interface IMobileViewContext {
  isMobileView: boolean;
}

export const MobileViewContext = createContext<IMobileViewContext>(
  {} as IMobileViewContext
);

export const MobileViewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <MobileViewContext.Provider value={{ isMobileView: useIsMobile() }}>
      {children}
    </MobileViewContext.Provider>
  );
};
